import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './Carousel.css';

export default function Carousel({ children }) {
	const settings = {
		dots: false,
		infinite: true,
		speed: 1500,
		autoplay: true,
		autoplaySpeed: 4500,
		cssEase: 'linear',
		fade: true,
		pauseOnHover: false,
	};
	const styleSlider = {
		overflow: 'hidden',
	};
	return (
		<>
			<Slider {...settings} style={styleSlider}>
				{children}
			</Slider>
		</>
	);
}
